.main {
  position: relative;
  background: #000;
  color: #f5f2ef;
  .header-wrap {
    // background-color: #000;
  }
  .animated-title {
    font-size: 120px;
    letter-spacing: -5px;
    font-weight: 100;
    position: sticky;
    height: 200px;
    overflow-x: hidden;
    overflow-y: hidden;
    text-transform: uppercase;
    z-index: 2;
    top: 0;
    // background: #ec6519;
  }
  .animated-title .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 200s linear infinite;
    transform: rotate(90deg);
  }
}
@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

body {
  margin: 0;
  font-family: 'Montserrat', 'Pretendard';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-width: 375px;
  padding: 30px;
  word-break: break-all;
  -webkit-text-size-adjust: none;
}

code {
  font-family: 'Montserrat', 'Pretendard';
}

/* PC */
.header-wrap {
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  .current-ci {
    width: 400px;
    padding: 50px 30px 30px 30px;
  }
}
.container {
  position: relative;
  width: 100%;
  padding: 0 40px 40px 40px;
  box-sizing: border-box;
}

.copyright {
  position: sticky;
  top: 30px;
}
.copyright p {
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
}

.content {
  padding-right: 0px;
  box-sizing: border-box;
}
.content .sec2 {
  padding-top: 200px;
}
.content .sec2 ul {
  display: flex;
}
.content .sec2 ul li {
  width: 50%;
  max-width: 286px;
}
.content .sec2 ul li:nth-child(1) {
  margin-right: 125px;
}
.content .sec2 dl {
  width: 286px;
}
.content .sec2 dl dt {
  font-size: 89px;
  line-height: 106px;
  font-weight: 600;
}
.content .sec2 dl dd.txt0 {
  padding: 19px 0 0 0;
  font-weight: 400;
  font-size: 34px;
  line-height: 42px;
}
.content .sec2 dl dd.txt1 {
  padding: 21px 0 0 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: -0.03em;
}
.content .sec3 {
  padding-top: 200px;
}
.content .sec3 p {
  font-weight: 400;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.03em;
}
.content .sec4 {
  padding-top: 40px;
}
.content .sec4 p {
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
}
footer {
  position: relative;
}
footer .mail {
  padding: 300px 0 60px 0;
}
footer .mail a {
  font-weight: 300;
  font-size: 48px;
  line-height: 57px;
  border-bottom: 2px solid #fff;
}
.pc_txt {
  display: block;
}
.mo_txt {
  display: none;
}
footer .sns {
  position: Absolute;
  bottom: 60px;
  right: 79px;
}
footer .sns ul {
}
footer .sns ul li {
  padding-bottom: 10px;
}
footer .sns ul li:last-child {
  padding-bottom: 0;
}
footer .sns ul li a {
  font-size: 18px;
  line-height: 24px;
  border-bottom: 1px solid #fff;
}
footer .sns ul li.tel {
  padding-top: 30px;
}
footer .sns ul li.tel a {
  font-size: 20px;
  line-height: 24px;
  border-bottom: 0;
}

/* 태블릿 */
@media screen and (max-width: 1000px) {
  .header-wrap {
    .current-ci {
      width: 100%;
    }
  }
  .copyright {
    display: inline-block;
    width: auto;
    position: static;
    padding: 20px 0 0 0;
    text-align: right;
  }
  .copyright p {
    font-size: 16px;
    line-height: 24px;
  }
  .content .sec2 {
    padding-top: 70px;
  }

  .content .sec2 ul li {
  }
  .content .sec2 ul li:nth-child(1) {
    margin: 0;
    padding-bottom: 0px;
  }
  .content .sec2 dl {
    display: inline-block;
    width: 185px;
    text-align: left;
  }
  .content .sec2 dl dt {
    font-size: 42px;
    line-height: 50px;
  }
  .content .sec2 dl dd.txt0 {
    font-size: 20px;
    line-height: 30px;
  }
  .content .sec2 dl dd.txt1 {
    font-size: 16px;
    line-height: 27px;
  }
  .content .sec3 {
    padding-top: 100px;
  }
  .content .sec3 p {
    font-size: 16px;
    line-height: 26px;
  }
  .content .sec4 {
    padding-top: 40px;
  }
  .content .sec4 p {
    font-size: 17px;
    line-height: 26px;
    font-weight: 400;
  }
  footer .mail {
    display: inline-block;
    padding: 150px 0 30px 0;
  }
  footer .mail a {
    font-size: 35px;
    line-height: 42px;
  }
  footer .sns {
    position: static;
    display: inline-block;
    padding: 0 0 80px 46px;
  }
  footer .sns ul li {
    padding-bottom: 6px;
  }
  footer .sns ul li a {
    font-size: 15px;
  }
  footer .sns ul li.tel a {
    font-size: 16px;
  }
}
/* 모바일 */
@media screen and (max-width: 600px) {
  .header-wrap {
    .current-ci {
      width: 100%;
    }
  }
  .pc_txt {
    display: none;
  }
  .mo_txt {
    display: block;
  }
  .copyright {
    display: inline-block;
    position: static;
    width: 100%;
    padding: 20px 0 0 0;
    text-align: right;
  }
  .content .sec2 ul li:nth-child(1) {
    margin: 0;
    padding-bottom: 60px;
  }
  .content .sec2 ul {
    display: block;
    box-sizing: border-box;
  }
  .content .sec2 ul li {
    display: block;
    width: 100%;
    max-width: 100%;
    text-align: left;
  }
  footer .sns {
    position: static;
    display: block;
    padding: 0 0 80px 0;
  }
  footer .sns ul li {
    padding-bottom: 6px;
  }
  footer .sns ul li a {
    font-size: 15px;
  }
  footer .sns ul li.tel a {
    font-size: 16px;
  }
}
@media screen and (max-width: 400px) {
  .header-wrap {
    .current-ci {
      width: 100%;
    }
  }
  .copyright {
    padding: 20px 0 0 0;
    text-align: right;
  }
  .content .sec2 ul li {
    display: block;
    width: 100%;
    max-width: 100%;
    text-align: right;
  }
}

.wireframe {
  color: #333;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper .swiper-slide {
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
